import qs from "qs";
import React from "react";
import { SearchIndexItem } from "../types";
import ClientSearch from "../components/ClientSearch";
import Layout from "../components/layout";

type Props = {
  pageContext: {
    searchData: {
      allFilms: SearchIndexItem[];
      allCastAndCrew: SearchIndexItem[];
      options: any;
    };
  };
  location: any;
};
const SearchTemplate: React.FC<Props> = ({ pageContext, location }) => {
  const { searchData } = pageContext;
  const { allFilms, allCastAndCrew } = searchData;

  return (
    <Layout>
      <ClientSearch
        data={[...allCastAndCrew, ...allFilms]}
        searchQuery={
          qs.parse(location.search, { ignoreQueryPrefix: true }).q as
            | string
            | undefined
        }
      />
    </Layout>
  );
};
export default SearchTemplate;
