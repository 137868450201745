import React, { useEffect, useState } from "react";
import * as JsSearch from "js-search";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { SearchIndexItem } from "../types";

import { navigate } from "gatsby-link";
import { makeStyles } from "@material-ui/styles";
import Badge from "./Badge";

const useStyles = makeStyles({
  paper: {
    marginTop: 5,
    marginBottom: 5,
    padding: "5px 0px",
    cursor: "pointer"
  }
});

const ClientSearch: React.FC<{
  data: SearchIndexItem[];
  searchQuery?: string;
}> = ({ data, searchQuery }) => {
  const [search, setSearch] = useState<JsSearch.Search | null>(null);
  const [results, setResults] = useState<SearchIndexItem[]>([]);

  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search("heading");
    dataToSearch.addIndex("description");
    dataToSearch.addIndex("heading");

    dataToSearch.addDocuments(data);
    setSearch(dataToSearch);
  };

  const searchData = () => {
    if (!search || !searchQuery) return;
    setResults(search.search(searchQuery) as any);
  };

  useEffect(() => {
    rebuildIndex();
    searchData();
  }, []);

  useEffect(() => {
    searchData();
  }, [searchQuery, search]);

  return (
    <Container>
      <Typography variant="h5">Search Results</Typography>
      <ListItems items={results} />
    </Container>
  );
};

export default ClientSearch;

function ListItems({ items }: any) {
  const classes = useStyles();
  if (!items) return <div>no items</div>;
  return (
    <List>
      {items.map((item: SearchIndexItem) => (
        <Paper
          className={classes.paper}
          onClick={() => navigate(item.path, { state: { navigatedTo: true } })}
          key={item.heading}
        >
          <ListItem>
            {item.description === "director" ||
            item.description === "actor" ? null : (
              <Badge
                rating={item.rating}
                style={{
                  marginRight: 10,
                  transform: "translateY(12%)"
                }}
              />
            )}
            <ListItemText primary={item.heading} secondary={item.description} />
          </ListItem>
        </Paper>
      ))}
    </List>
  );
}
